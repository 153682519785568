// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';


function Body(){
  return(
    <div className='body text-white'>
        <h1 className='text-white'>Welcome to Belize Code</h1>
        <p>Something amazing coming soon....................</p>
    </div>
  );
}

function App() {
  return (
    <Body />
  );
}

export default App;
